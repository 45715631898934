import { useEffect, useRef, useState, useContext } from "react";
import { useLocation, useSearchParams } from "react-router-dom";


import { offerwallapi } from "../../config/axiosconfig";
import { ClientContext } from "../../context/client/ClientContext";
import { TenantContext } from "../../context/tenant/TenantContext";
import CryptoJS from "crypto-js";
import { LanguageContext } from "../../context/language/LanguageContext";
// import { encode, encodeURL } from 'js-base64';
import { Link, useNavigate } from "react-router-dom";

import "./pagenotfound.css";
import { CardContext } from "../../context/card/CardContext";
import { Spinner } from "react-bootstrap";
import Cookies from "universal-cookie";


export default function PageNotFound() {
    //?info={
    // CID:efd952b5-681c-ef11-b3e4-b8cef6052529,
    // EUID:42a4f71dca6414d6d802809ef0ed38508a3e6c4c,
    // SUCCESS:true,
    // UID:ed3e7f51-ef93-4971-adff-85581b186d8a
    // }
    const cookies = new Cookies();
    const url = useLocation().search;//.pathname.split("/");
    // console.log("A", url)
    const [searchParams, setSearchParams] = useSearchParams();
    // console.log("A", searchParams.get("info"))


    // const urlPath = url[1];
    useEffect(() => {
        window.top.postMessage(window.location.href, "*");
        if (searchParams.get("info")) {
            document.title = "VISA - Japan";
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = `${process.env.REACT_APP_CLOUDFRONT}favicons/visa-japan.ico`;
        }
        // CardAddProcess();
    }, []);




    const navigate = useNavigate();
    const { tenant, tenantdispatch } = useContext(TenantContext);
    const { client, clientdispatch } = useContext(ClientContext);
    const { language, languagedispatch } = useContext(LanguageContext);
    const { card, carddispatch } = useContext(CardContext);


    // const [showConfirmModal, setShowConfirmModal] = useState(false);
    // const [showEmailSubscriptionConfirmModal, setShowEmailSubscriptionConfirmModal] = useState(false);
    // const [confirmMessage, setConfirmMessage] = useState("");
    // const [confirmType, setConfirmType] = useState("");
    // const [isDisabled, setIsDisabled] = useState(true);
    // const [visaParams, setVisaParams] = useState({});
    // const [rsaEncryptedVals, setRSAEncryptedVals] = useState();

    // const [cardList, setCardList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState("");
    const [langConts, setLangConts] = useState();
    const [tenantObj, setTenantObj] = useState();

    // const [selectedCard, setSelectedCard] = useState({});
    // const [eventExists, setEventExist] = useState(false);
    // const [expressURL, setExpressURL] = useState("");
    // const [isLoaded, setIsLoaded] = useState(false);
    // const txtVisaCardNumber = useRef();
    // const ifrmMini = useRef();
    // const chkEmailSubscription = useRef();


    useEffect(() => {

        // if (tenant) getLanguages();

        // if (localStorage.getItem("l")) {
        //     const lang = JSON.parse(localStorage.getItem("l"))
        //     if (lang.languageid === 490) {
        //         localStorage.removeItem("l")
        //     }
        // }

        // const { languageid, languagecode } = { languageid: 489, languagecode: "en", languagename: "English" }

        const { languageid, languagecode } = (localStorage.getItem("l")) ? JSON.parse(localStorage.getItem("l")) : { languageid: 489, languagecode: "en", languagename: "English" }


        getLanguage(languageid, languagecode);


    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getTenant = async (lanid) => {

        // const owclient = cookies.get("ow-c");
        // setTenantObj(owclient);
        tenantdispatch({ type: "TENANT_START" });
        try {
            const res = await offerwallapi.get("/api/tenants/tenantgetbyurlname", {
                params: {
                    tenanturlname: client.tenanturlname,
                    languageid: lanid
                }
            });
            if (res.data.length === 0) navigate("/");

            // console.log(res.data)
            const tenantobj = res.data


            await localStorage.setItem("l", JSON.stringify({ languageid: tenantobj.languageid, languagecode: tenantobj.languagecode }));

            // res.data.tenantmasterconfig = JSON.parse(res.data.tenantmasterconfig)
            // res.data.tenantcontentconfig = JSON.parse(res.data.tenantcontentconfig)


            tenantdispatch({ type: "TENANT_SUCCESS", payload: res.data });

            // document.title = res.data.tenantmasterconfig.title;


            return { languageid: tenantobj.languageid, languagecode: tenantobj.languagecode }

        } catch (err) {
            tenantdispatch({ type: "TENANT_FAIL", payload: err });
        }
    }

    const getLanguage = async (lanid, lancode) => {


        const { languageid, languagecode } = await getTenant(lanid);
        try {
            languagedispatch({ type: "LANGUAGE_START" });
            const res = await offerwallapi.get("/api/public/multilingual/" + languageid);

            languagedispatch({ type: "LANGUAGE_SUCCESS", payload: JSON.parse(res.data.languagecontent) });
            setLangConts(JSON.parse(res.data.languagecontent));
            CardAddProcess(JSON.parse(res.data.languagecontent));
        } catch (err) {
            languagedispatch({ type: "LANGUAGE_FAIL", payload: err });
        }
        // if (client && client.defaultlanguageid !== languageid) updateClientLanguage(languageid, languagecode);
    }


    const CardAddProcess = async (lancon) => {
        if (searchParams.get("info")) {

            setMessage("");
            setLoader(true);
            // console.log("CARD ADD PROCESS")
            // const encryptparams = await GetVisaParams();

            // const info = `{EUID:${client.externaluserid},PC:${tenant.tenantpromocode},EM:${encryptparams.email}}`;

            // const key = CryptoJS.enc.Utf8.parse(encryptparams.visa_key);
            // const iv = CryptoJS.enc.Utf8.parse(encryptparams.visa_iv);

            // const encryptedInfo = CryptoJS.AES.encrypt(info, key, {
            //     iv: iv,
            //     mode: CryptoJS.mode.CBC,
            //     padding: CryptoJS.pad.Pkcs7,
            // }).toString();

            const returnparams = searchParams.get("info").replace("{", "").replace("}", "");
            // {CID:425e6472-9c44-e511-a87a-4851b7b88ee9,SUCCESS:true,UID:8bb0366b-ca89-4339-a900-521ee1e82398}
            const cid = returnparams.split(",")[0].replace("CID:", "");
            const euid = returnparams.split(",")[1].replace("EUID:", "");
            const success = returnparams.split(",")[2].replace("SUCCESS:", "");
            const uid = returnparams.split(",")[3].replace("UID:", "");


            const errorcode = returnparams.split(",")[3].replace("UID:", "");
            const errormessage = returnparams.split(",")[3].replace("UID:", "");

            //https://www.stg.offers-exchange.com/?info={
            //     ERRCODE:Invalid_EnrollmentEligibility,
            //     ERRMSG:Invalid_EnrollmentEligibility,
            //     EUID:42a4f71dca6414d6d802809ef0ed38508a3e6c4c,
            //     SUCCESS:false
            // }
            const newcards = await getCards()

            let cardExist = false;
            if (newcards) {
                for (let i = 0, j = newcards.length; i < j; i++) {
                    if (cid === newcards[i].cardnumber) {
                        cardExist = true;
                        break;
                    }
                }
            }

            if (!cardExist) {
                // console.log("CARD ADD PROCESS - CARD NOT EXIST")
                // console.log("SUCCESS : ", success)
                if (success === "true") {
                    // console.log("CARD ADD PROCESS - VISA ENROLLMENT SUCCESS")
                    try {
                        const userdata = await offerwallapi.post("/api/cards/getvisacarduser", {
                            tenantid: client.tenantid,
                            clientid: client.clientid,
                            userkey: client.externaluserid
                        });

                        try {
                            //const clientobj = { ...client }
                            //clientobj.visaclientid = uid;
                            //clientobj.isenrolled = 1;
                            //clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobj });
                            const cardobj = {};
                            const carddetails = userdata.data.enrollmentRecord.cardDetails;
                            cardobj.isdefault = (carddetails.length === 1) ? 1 : 0;
                            cardobj.clientid = client.clientid;
                            cardobj.tenantid = client.tenantid;
                            //tenant.tenantid;
                            cardobj.UserKey = client.externaluserid;
                            cardobj.UserId = uid;
                            cardobj.CardId = cid;

                            for (let i = 0, j = carddetails.length; i < j; i++) {
                                if (cid === carddetails[i].CardId) {
                                    cardobj.ReferenceId = carddetails[i].ServiceKey;
                                    cardobj.CardLast4 = carddetails[i].CardLast4;
                                    break;
                                }
                            }
                            // console.log("CARD ADD PROCESS - CARD OBJECT : ", cardobj)
                            const clientobject = await offerwallapi.post("/api/cards/cardcreate", cardobj);

                            //clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobject });
                            // console.log("CARD ADD PROCESS - CARD ADDED OT OW")
                            // setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);
                            // console.log("CARD ADD PROCESS - SUCCESS : ", language?.success_res.success_l4)
                            clientdispatch({ type: "CLIENT_SUCCESS", payload: clientobject.data });
                            setMessage(lancon?.success_res.success_l4);
                            setTimeout(() => {
                                setLoader(false)
                                window.location.href = `/${client.tenanturlname}/user/mycards`;
                            }, 2000);

                            //getCards();

                        } catch (err) {
                            console.log("OW-ERR-ADDCARD 1: ", err)
                            // setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);

                            setMessage(lancon?.error_res.error_l10);
                            setTimeout(() => {
                                setLoader(false)
                                window.location.href = `/${client.tenanturlname}/user/mycards`;
                            }, 2000);
                        }
                    } catch (err) {
                        console.log("OW-ERR-ADDCARD 2: ", err)
                        // setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);

                        setMessage(lancon?.error_res.error_l10);
                        setTimeout(() => {
                            setLoader(false)
                            window.location.href = `/${client.tenanturlname}/user/mycards`;
                        }, 2000);
                    }


                } else {
                    // console.log("MINIXPRESS-ERR-ADDCARD : ", returnparams)
                    // setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);
                    setMessage(lancon?.error_res.error_l10);
                    setTimeout(() => {
                        setLoader(false)
                        window.location.href = `/${client.tenanturlname}/user/mycards`;
                    }, 2000);
                }
                //check for SUCCESS=TRUE to get CardId and UserId and perform exception handling otherwise, etc..
            } else {
                console.log("OW-ERR-CARDEXIST : ", cardExist)
                // setExpressURL(`${encryptparams.expressmini_url}${encryptparams.community_code}?info=${encodeURIComponent(encryptedInfo)}&info1=${encodeURIComponent(encryptparams.visa_info1_express)}&info2=V${encryptparams.cert_version}`);

                setMessage(lancon?.error_res.error_l15);
                setTimeout(() => {
                    setLoader(false)
                    window.location.href = `/${client.tenanturlname}/user/mycards`;
                }, 2000);
            }
            // window.addEventListener("message", CardAddProcess, false);
        }
    }

    // const GetVisaParams = async () => {
    //     const encryptparams = await offerwallapi.get("/api/cards/enrolleevisaparams", {
    //         params: {
    //             tenantid: 1004, 
    //             //tenant.tenantid,
    //             clientid: client.clientid
    //         }
    //     });
    //     return encryptparams.data;
    // }

    const getCards = async () => {
        try {
            carddispatch({ type: "CARD_START" });
            const cards = await offerwallapi.get("/api/cards/cardgetall", {
                params: {
                    clientid: client.clientid,
                    tenantid: client.tenantid
                    //tenant.tenantid
                }
            });
            carddispatch({ type: "CARD_SUCCESS", payload: cards.data });
            return cards.data; // setMyCards(cards.data);
        } catch (error) {
            console.log("OW-ERR-GETCARDS : ", error)
            carddispatch({ type: "CARD_FAIL", payload: error });
        }

        // setCardList(cards.data);
    }
    //process.env.REACT_APP_EXPRESSMINI_REDIRECT_URL
    return <div>
        <h1 className="text-center">{(url === "") ?
        
        <div class="welcome-page-text">
            <span class="welcome-page-blue">Welcome to offers exchange</span>
        </div>
        : ""}</h1>

        {
            (loader) ? <div className="ow-full-overlay">
                <div className="position-absolute ow-content-center text-center">
                    <Spinner animation="border" backdrop="static" variant="light" />
                    <h3 className="text-light">{message}</h3>
                </div>
            </div>
                : ""
        }
    </div>
}